.App {
  font-family: sans-serif;
}

.header {
  text-align: center;
}

.carousel-container {
  margin: 20px;
}

.selected-image {
  width: 100%;
  height: 500px;
  margin-bottom: 8px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}


@media only screen and (max-width: 600px) {
  .selected-image {
    width: 100%;
    height: 300px;
    margin-bottom: 8px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.carousel {
  position: relative;
}

.carousel__images {
  display: flex;
  max-width: 100%;
  height: 300;
  overflow-x: hidden;
}

.carousel__image-selected {
  border: 3px solid #ffa700 !important;
}

.carousel__image {
  margin-right: 10px;
  height: 150px;
  min-width: 150px;
  border: 3px solid #ffa70000;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 600px) {

  .carousel__image {
    margin-right: 10px;
    height: 75px;
    min-width: 75px;
    border: 3px solid #ffa70000;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}


.carousel__button {
  position: absolute;
  top: 40%;
}

.carousel__button-left {
  left: 10px;
}

.carousel__button-right {
  right: 10px;
}